<template>
  <div class="home">
    <section class="hero is-large custom-background">
      <div class="hero-body has-text-centered">
        <p class="title mb-6">
          HarnessHardware
        </p>
      </div>
    </section>  
    
    <div class="latestProducts columns is-multiline is-mobile is-centered">
      <div class="column is-12">
          <h2 class="latestProductsTitle is-size-2 has-text-centered">Latest products</h2>
      </div>

      <ProductBox
        v-for="product in latestProducts"
        v-bind:key="product.id"
        v-bind:product="product" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import ProductBox from '@/components/ProductBox'

export default {
  name: 'Home',
  data() {
    return {
      latestProducts: []
    }
  },
  components: {
    ProductBox
  },
  mounted() {
    this.getLatestProducts()

    document.title = 'Home | HarnessHardware'
  },
  methods: {
    getLatestProducts() {
      axios
        .get('/api/v1/latest-products/')
        .then(response => {
          console.log('API response:', response.data);
          this.latestProducts = response.data;
        })
        .catch(error => {
          console.error('Error fetching latest products:', error);
        });
    }
  },
}
</script>

<style 
scoped>
*{
    box-sizing: border-box;
    margin: 0;
}

.custom-background {
  background-image: url("../assets/homeViewBG.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 500px;
}
.title {
  color: ghostwhite;
  font-size: 2.5rem;
}

.latestProducts {
  background: ghostwhite;
}

.latestProductsTitle {
  color: #3c5066;
}
</style>


