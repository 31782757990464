<template>
    <div class="page-checkout">
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="title">Checkout</h1>
        </div>
  
        <div class="column is-12 box">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Product</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in cart.items" :key="item.product.id">
                <td>{{ item.product.name }}</td>
                <td>€{{ item.product.price }}</td>
                <td>{{ item.quantity }}</td>
                <td>€{{ getItemTotal(item).toFixed(2) }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">Total</td>
                <td>{{ cartTotalLength }}</td>
                <td>€{{ cartTotalPrice.toFixed(2) }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
  
        <div class="column is-12 box">
          <h2 class="subtitle">Shipping details</h2>
          <p class="has-text-grey mb-4">* All fields are required</p>
  
          <div class="columns is-multiline">
            <div class="column is-6">
              <div class="field">
                <label>First name*</label>
                <div class="control">
                  <input type="text" class="input" v-model="first_name" required />
                </div>
              </div>
  
              <div class="field">
                <label>Last name*</label>
                <div class="control">
                  <input type="text" class="input" v-model="last_name" required />
                </div>
              </div>
  
              <div class="field">
                <label>E-mail*</label>
                <div class="control">
                  <input type="email" class="input" v-model="email" required />
                </div>
              </div>
  
              <div class="field">
                <label>Phone*</label>
                <div class="control">
                  <input type="text" class="input" v-model="phone" required />
                </div>
              </div>
            </div>
  
            <div class="column is-6">
              <div class="field">
                <label>Address*</label>
                <div class="control">
                  <input type="text" class="input" v-model="address" required />
                </div>
              </div>
  
              <div class="field">
                <label>Zip code*</label>
                <div class="control">
                  <input type="text" class="input" v-model="zipcode" required />
                </div>
              </div>
  
              <div class="field">
                <label>Place*</label>
                <div class="control">
                  <input type="text" class="input" v-model="place" required />
                </div>
              </div>
            </div>
          </div>
  
          <div class="notification is-danger mt-4" v-if="errors.length">
            <p v-for="error in errors" :key="error">{{ error }}</p>
          </div>
  
          <hr>
  
          <template v-if="cartTotalLength">
            <!-- Add your "Order" button here with the @click handler to handle the order submission -->
            <button class="button is-dark" @click="submitOrder">Order</button>
          </template>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'Checkout',
    data() {
      return {
        cart: {
          items: []
        },
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        zipcode: '',
        place: '',
        errors: []
      };
    },
    mounted() {
      document.title = 'Checkout | HarnessHardware';
      this.cart = this.$store.state.cart;
    },
    methods: {
      getItemTotal(item) {
        return item.quantity * item.product.price;
      },
      submitOrder() {
        this.errors = [];
  
        // Validate the form fields
        if (this.first_name === '') {
          this.errors.push('The first name field is missing!');
        }
        if (this.last_name === '') {
          this.errors.push('The last name field is missing!');
        }
        if (this.email === '') {
          this.errors.push('The email field is missing!');
        }
        if (this.phone === '') {
          this.errors.push('The phone field is missing!');
        }
        if (this.address === '') {
          this.errors.push('The address field is missing!');
        }
        if (this.zipcode === '') {
          this.errors.push('The zip code field is missing!');
        }
        if (this.place === '') {
          this.errors.push('The place field is missing!');
        }
  
        // If no errors, you can handle the order submission here
        if (!this.errors.length) {
          // Add code to submit the order to your server or handle the order as needed.
          // After successful submission, you can clear the cart and redirect to a success page.
          this.$store.commit('clearCart');
          this.$router.push('/cart/success');
        }
      }
    },
    computed: {
      cartTotalPrice() {
        return this.cart.items.reduce((acc, curVal) => {
          return (acc += curVal.product.price * curVal.quantity);
        }, 0);
      },
      cartTotalLength() {
        return this.cart.items.reduce((acc, curVal) => {
          return (acc += curVal.quantity);
        }, 0);
      }
    }
  };
  </script>
  