<template>
  <div class="page-product">
    <div class="columns is-multiline product-info">
      <div class="column is-9 m-4">
        <!-- Swiper for displaying multiple images -->
        <swiper :options="swiperOptions">
          <swiper-slide v-for="image in product.images" :key="image.id">
            <img class="mx-auto d-block" :src="image.image" style="max-height: 50vh; width: auto;" />
          </swiper-slide>
        </swiper>

        <h1 class="title">{{ product.name }}</h1>
        <p>{{ product.description }}</p>
      </div>

      <div class="column is-2">
        <p class="price"><strong>Price</strong>€{{ product.price }}</p>

        <div class="field has-addons mt-6" v-if="$store.state.isAuthenticated">
          <div class="control">
            <input type="number" class="input" min="1" v-model="quantity" />
          </div>
          <div class="control">
            <button class="button is-dark" @click="addToCart">Add to cart</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css'; // Ensure this line is included
import axios from 'axios';
import { toast } from 'bulma-toast';
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'Product',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      product: {},
      quantity: 1,
      swiperOptions: { // Define swiperOptions here
        // Your swiper options
      },
    };
  },
  mounted() {
    this.getProduct();
  },
  methods: {
    async getProduct() {
      this.$store.commit('setIsLoading', true);

      const category_slug = this.$route.params.category_slug;
      const product_slug = this.$route.params.product_slug;

      try {
        const response = await axios.get(`/api/v1/products/${category_slug}/${product_slug}`);
        this.product = response.data;
        console.log('Product Data:', this.product);

        document.title = this.product.name + ' | HarnessHardware';
      } catch (error) {
        console.error('Error fetching product:', error);
      } finally {
        this.$store.commit('setIsLoading', false);
      }
    },
    addToCart() {
      if (isNaN(this.quantity) || this.quantity < 1) {
        this.quantity = 1;
      }

      const item = {
        product: this.product,
        quantity: this.quantity,
      };

      this.$store.commit('addToCart', item);

      toast({
        message: 'Product added to the cart',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 2000,
        position: 'bottom-right',
      });
    },
  },
};
</script>

<style scoped>
.product-info {
  text-align: center;
}
.price {
  font-size: 2rem
}
</style>
