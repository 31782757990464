import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import HomeView from '../views/HomeView.vue';

import Product from '../views/Product.vue';
import Category from '../views/Category.vue';
import Search from '../views/Search.vue';
import Cart from '../views/Cart.vue';
import SignUp from '../views/SignUp.vue';
import LogIn from '../views/LogIn.vue';
import MyAccount from '../views/MyAccount.vue';
import Checkout from '../views/Checkout.vue';
import Success from '../views/Success.vue';
import AllProducts from '../views/AllProducts.vue';
import Contact from '../views/Contact.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/all-products', // Added route for displaying all products
    name: 'AllProducts',
    component: AllProducts,
  },
  {
    path: '/:category_slug/:product_slug',
    name: 'Product',
    component: Product,
  },
  {
    path: '/:category_slug',
    name: 'Category',
    component: Category,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
  },
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/cart/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/cart/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      requireLogin: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the user is authenticated
  if (store.state.isAuthenticated) {
    // If the user is authenticated, allow access to all routes
    next();
  } else if (to.matched.some((record) => record.meta.requireLogin)) {
    // If the route requires authentication, redirect to the login page with the current path as a query parameter
    next({ name: 'LogIn', query: { to: to.fullPath } });
  } else {
    // For all other routes, allow access
    next();
  }
});

export default router;
