<!-- Contact.vue -->

<template>
    <div class="contact-container">
      <section class="hero is-primary">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">Contact Us</h1>
            <h2 class="subtitle">Feel free to reach out to us through the following channels:</h2>
          </div>
        </div>
      </section>
  
      <div class="section">
        <div class="container">
          <div class="columns">
            <div class="column is-half">
              <div class="contact-methods">
                <a :href="'mailto:' + email" class="contact-icon">
                  <i class="fas fa-envelope fa-3x"></i>
                </a>
                <a :href="'https://www.instagram.com/' + instagram" target="_blank" rel="noopener noreferrer" class="contact-icon">
                  <i class="fab fa-instagram fa-3x"></i>
                </a>
                <!-- Add other contact methods as needed -->
              </div>
            </div>
            <div class="column is-half">
              <div class="contact-form">
                <h2 class="title">Or send us a message:</h2>
                <form @submit.prevent="submitForm">
                  <div class="field">
                    <label class="label">Name</label>
                    <div class="control">
                      <input class="input" type="text" v-model="formData.name" required>
                    </div>
                  </div>
  
                  <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                      <input class="input" type="email" v-model="formData.email" required>
                    </div>
                  </div>
  
                  <div class="field">
                    <label class="label">Phone number</label>
                    <div class="control">
                      <input class="input" type="number" v-model="formData.email" required>
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Message</label>
                    <div class="control">
                      <textarea class="textarea" v-model="formData.message" required></textarea>
                    </div>
                  </div>
  
                  <div class="control">
                    <button class="button is-success">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: 'info@harnesshardware.com',
        instagram: 'harnesshardware',
        formData: {
          name: '',
          email: '',
          message: '',
          number: ''
        }
      };
    },
    methods: {
      submitForm() {
        // Add logic to handle form submission, e.g., sending data to a server
        console.log('Form submitted:', this.formData);
  
        // Example: Send form data using Axios
        axios.post('https://api.example.com/contact', this.formData)
          .then(response => {
            // Handle the success response, if needed
            console.log('Server response:', response);
          })
          .catch(error => {
            // Handle the error, if any
            console.error('Error submitting form:', error);
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .contact-container {
    padding: 2rem 0;
  }
  
  .contact-methods {
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .contact-icon {
    margin: 0 1rem;
    color: #3273dc; /* Adjust the color as needed */
  }
  
  .contact-icon:hover {
    color: #0552b5; /* Adjust the hover color as needed */
  }
  
  .contact-form {
    background-color: #f8f8f8;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .field {
    margin-bottom: 1.5rem;
  }
  
  label {
    font-weight: bold;
  }
  
  button {
    background-color: #4caf50;
    color: white;
  }
  
  button:hover {
    background-color: #45a049;
  }
  </style>
  