import { createStore } from 'vuex';

export default createStore({
  state: {
    cart: {
      items: [],
    },
    isAuthenticated: false,
    token: '',
    isLoading: false,
    user: null, // Add a user object to store user information
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'));
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart));
      }

      // Check for an authentication token in local storage
      const token = localStorage.getItem('token');
      if (token) {
        state.isAuthenticated = true;
        state.token = token;
      }

      // Check for user information in local storage
      const userData = localStorage.getItem('user');
      if (userData) {
        state.user = JSON.parse(userData);
      }
    },
    addToCart(state, item) {
      const exists = state.cart.items.find((i) => i.product.id === item.product.id);

      if (exists) {
        exists.quantity += parseInt(item.quantity);
      } else {
        state.cart.items.push(item);
      }

      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
    setIsLoading(state, status) {
      state.isLoading = status;
    },
    setIsAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    clearAuthentication(state) {
      state.isAuthenticated = false;
      state.token = '';
      localStorage.removeItem('token');
      state.user = null; // Clear the user information
      localStorage.removeItem('user');
    },
    // Add a mutation to set user information
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
  },
  actions: {
    // You can implement actions for login, logout, etc. here
  },
  modules: {},
});
