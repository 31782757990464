<template>
  <div class="page-all-products">
    <div class="columns is-multiline is-mobile is-centered">
      <div class="column is-12">
        <h2 class="is-size-2 has-text-centered">All Products</h2>

        <!-- Pagination Controls -->
        <div class="buttons has-addons is-centered mt-4">
          <button
            class="button"
            :class="{ 'is-dark': productsPerPage === 12 && !loadAll }"
            @click="setProductsPerPage(12)"
          >
            12
          </button>
          <button
            class="button"
            :class="{ 'is-dark': productsPerPage === 24 && !loadAll }"
            @click="setProductsPerPage(24)"
          >
            24
          </button>
          <button
            class="button"
            :class="{ 'is-dark': loadAll }"
            @click="loadAllProducts"
          >
            All
          </button>
        </div>
      </div>

      <!-- Display Products -->
      <ProductBox
        v-for="product in displayedProducts"
        :key="product.id"
        :product="product"
      />

      <!-- Load More Button -->
      <div class="column is-12 has-text-centered mt-4 mb-4" v-if="showLoadMoreButton">
        <button class="button is-dark" @click="loadMoreProducts">Load More</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ProductBox from '@/components/ProductBox.vue';

export default {
  name: 'AllProducts',
  components: {
    ProductBox,
  },
  data() {
    return {
      allProducts: [],
      displayedProducts: [],
      productsPerPage: 12,
      page: 1,
      loadAll: false,
    };
  },
  mounted() {
    this.getAllProducts();
    document.title = 'All Products | HarnessHardware';
  },
  methods: {
    async getAllProducts() {
      this.$store.commit('setIsLoading', true);

      try {
        const response = await axios.get('/api/v1/all-products/');
        this.allProducts = response.data;
        this.displayedProducts = this.allProducts.slice(0, this.productsPerPage);
      } catch (error) {
        console.error('Error fetching all products:', error);
      } finally {
        this.$store.commit('setIsLoading', false);
      }
    },
    setProductsPerPage(amount) {
      this.loadAll = false;
      this.productsPerPage = amount;
      this.displayedProducts = this.allProducts.slice(0, this.productsPerPage);
      this.page = 1;
    },
    loadMoreProducts() {
      const startIndex = this.page * this.productsPerPage;
      const endIndex = startIndex + this.productsPerPage;
      this.displayedProducts = [...this.displayedProducts, ...this.allProducts.slice(startIndex, endIndex)];
      this.page++;
    },
    loadAllProducts() {
      this.loadAll = true;
      this.displayedProducts = this.allProducts;
    },
  },
  computed: {
    showLoadMoreButton() {
      return !this.loadAll && this.displayedProducts.length < this.allProducts.length;
    },
  },
};
</script>
