<template>
  <div class="product column is-3 is-centered">
    <router-link class="productLink" :to="product.get_absolute_url">
      <div class="image">
        <!-- Display the first image directly -->
        <img :src="getFirstImageUrl" class="product-image">
      </div>

      <div class="productInfo">
        <h3 class="productName is-size-4">{{ product.name }}</h3>
        <p class="is-size-6 has-text-grey" v-if="product.material">Material: {{ product.material }}</p>
        <p class="is-size-6 has-text-grey">Dimensions: {{ product.dimensions }}</p>
        <p class="is-size-6 has-text-grey">Price: €{{ product.price }}</p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProductBox',
  props: {
    product: Object,
  },
  computed: {
    getFirstImageUrl() {
      // Check if the product has images and return the URL of the first image
      if (this.product.images && this.product.images.length > 0) {
        return this.product.images[0].image;
      } else {
        // Return the URL of the default image
        return require('@/assets/default.jpg');
      }
    }
  }
};
</script>

<style scoped>

.image {
    width: 100%;
    max-width: 200px;
    height: 200px; /* Set a fixed height for the image container */
    text-align: center; /* Center the image horizontally */
    display: flex; /* Use flexbox to center image vertically */
    justify-content: center; /* Center the image vertically */
    align-items: center; /* Center the image vertically */
    overflow: hidden; /* Ensure that images larger than the container are clipped */
}

.product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.productName {
    color: #3c5066;
}

.product {
    min-width: 192px;
}

.productLink {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
</style>
