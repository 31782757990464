<template>
  <div id="wrapper" @click="handleClick">
    <nav class="navbar">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img :src="require('@/assets/logo.png')" alt="Your Logo" style="max-height: 4rem;">
        </router-link>

        <a href="javascript:void(0);" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div class="navbar-menu" id="navbar-menu" v-bind:class="{'is-active': showMobileMenu }">
        <div class="navbar-start">
          <div class="navbar-item">
            <form method="get" action="/search">
              <div class="field has-addons">
                <div class="control">
                  <input type="text" class="input" placeholder="What are you looking for?" name="query">
                </div>

                <div class="control">
                  <button class="button is-success">
                    <span class="icon">
                      <i class="fas fa-search"></i>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="navbar-end">
          <router-link to="/all-products" class="navbar-item">Products</router-link>
          <router-link to="/contact" class="navbar-item">Contact</router-link>

          <div class="navbar-item">
            <div class="buttons">
              <template v-if="$store.state.isAuthenticated">
                <template v-if="$store.state.isStaff">
                  <a href="https://api.harnesshardware.com/admin" class="button is-light">Admin</a>
                </template>

                <router-link to="/my-account" class="button is-light">My account</router-link>
                <button class="button is-light" @click="logout">Logout</button> <!-- Logout button next to My Account -->

                <router-link to="/cart" class="button is-success">
                  <span class="icon"><i class= "fas fa-shopping-cart"></i></span>
                  <span>({{ cartTotalLength }})</span>
                </router-link>
              </template>

              <template v-else>
                <router-link to="/log-in" class="button is-light">Log in</router-link>
                <router-link to="/sign-up" class="button is-light">Sign up</router-link>
              </template>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div class="is-loading-bar has-text-centered" v-bind:class="{'is-loading': $store.state.isLoading }">
      <div class="lds-dual-ring"></div>
    </div>

    <section class="homeSection">
      <router-view/>
    </section>

    <footer class="footer">
      <h1 class="footerTitle">Get in touch</h1>
      <div class="getInTouch">
        <i class="fas fa-envelope fa"></i><a :href="'mailto:' + email" class="contact-icon">&nbsp;&nbsp;info@harnesshardware.com
        </a><br>
        <i class="fas fa-phone fa"></i>&nbsp;<a href="tel:+38162224400">+38162224400</a> <br>
        <i class="fas fa-map-pin fa"></i>&nbsp;&nbsp;&nbsp;Novosadska 4a, Backa Topola, Serbia
      </div>
      <div class="openingTime">
        <h1 class="openingTimesTitle">Opening times</h1>
        <div class="openingTimesTime">
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Monday       8-16<br>
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Tuesday      8-16<br>
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Wednesday    8-16<br>
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Thursday     8-16<br>
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Friday       8-16<br>
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Saturday     Closed<br>
          <i class="fas fa-light fa-clock"></i>&nbsp;&nbsp;Sunday       Closed<br>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      email : 'info@harnesshardware.com',
      showMobileMenu: false,
      cart: {
        items: []
      }
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

    const token = this.$store.state.token

    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }
  },
  mounted() {
    this.cart = this.$store.state.cart
  },
  computed: {
    cartTotalLength() {
      let totalLength = 0;

      for (let i = 0; i < this.cart.items.length; i++) {
        totalLength += this.cart.items[i].quantity;
      }
      return totalLength;
    }
  },
  methods: {
    logout() {
      // Clear authentication state and token
      this.$store.commit('setIsAuthenticated', false)
      this.$store.commit('setToken', '')

      // Clear the Cart items if needed
      this.$store.commit('clearCart')

      // Hide "My Account" and "Cart" buttons
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss">
@import '../node_modules/bulma';

*{
    box-sizing: border-box;
    margin: 0;
    white-space: wrap;
    word-break: break-word;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #ccc;
  border-color: #ccc transparent #ccc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.is-loading-bar {
  height: 0;
  overflow: hidden;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  &.is-loading {
    height: 80px;
  }
}

.navbar {
  background-color: #3c5066;
}

.navbar-burger span {
  color: ghostwhite;
}

.navbar-item {
  background-color: #3c5066;
  color:white;
  font-weight: bold;
}

.navbar-burger {
  height: auto;
}

.homeSection {
  margin: 0;
  padding: 0;
}

/* Style for the profile image in the dropdown */
.profile-image {
  border-radius: 50%;
  width: 40px; /* Adjust the size as needed */
  height: 40px;
  object-fit: cover;
}

/* Style for the language dropdown */
.has-dropdown {
  position: relative;
}

.footer {
  background: #3c5066;
  color: ghostwhite;
}

.footerTitle,
.openingTimesTitle {
  font-size: 1.5rem;
  font-family: sans-serif;
}

.openingTimesTitle {
  padding-top:1em
}

.contact-icon {
  text-decoration: none;
  color: ghostwhite;
}

</style>