import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

//axios.defaults.baseURL = 'http://127.0.0.1:8000'
axios.defaults.baseURL = 'https://api.harnesshardware.com'

const app = createApp(App);

app.use(store);
app.use(router);

// Make Axios available as $axios in your Vue components
app.config.globalProperties.$axios = axios;

// Add the logout method
app.config.globalProperties.logout = () => {
  // Clear authentication state and token
  store.commit('setIsAuthenticated', false);
  store.commit('setToken', '');

  // Remove the token from local storage
  localStorage.removeItem('token');

  // Redirect to the home page or any other route
  router.push('/');
};

app.mount('#app');
